@import "/src/styles/mixin";

.swiper-week-items {
  position: absolute !important;
  top: 210px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 13px;
  overflow: hidden;

  @media screen and (max-width: 1365px) {
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    position: relative !important;
    top: 10px;
  }

  .swiper {
    &-wrapper {
      padding-bottom: 32px;
    }

    &-button {
      @include arrowSwiper;
    }

    &-slide {
      max-width: 270px;

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      @media screen and (max-width: 1439px) {
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }

      @media screen and (max-width: 1365px) {
        max-width: 261px;
      }

      @media screen and (max-width: 1023px) {
        max-width: 209.4px;
      }

      @media screen and (max-width: 767px) {
        max-width: 139px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    &-pagination {
      @include swiperPagination;
      display: block;
      margin-top: 24px;
      bottom: -6px !important;
      z-index: 1;

      &-bullet {
        width: 10px !important;
        height: 10px !important;
      }

      &-bullet-active {
        background: var(--primary-color) !important;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
    }
  }
}
